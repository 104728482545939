import 'sly/dist/sly';
import 'jqueryui/jquery-ui';

export function slyHorizontal($frame, options = null) {
  const $slidee = $frame.children('ul').eq(0);
  const $wrap = $frame.parent();
  const baseOptions = {
    horizontal: true,
    itemNav: 'basic',
    smart: 1,
    activateOn: 'click',
    mouseDragging: 1,
    touchDragging: 1,
    releaseSwing: 1,
    startAt: 0,
    scrollBar: $wrap.find('.sly-scrollbar'),
    scrollBy: 1,
    pagesBar: $wrap.find('.sly-pages'),
    activatePageOn: 'click',
    speed: 300,
    elasticBounds: 1,
    easing: 'easeOutExpo',
    dragHandle: 1,
    dynamicHandle: 1,
    clickBar: 1,
    scrollTrap: 1,

    // Buttons
    forward: $wrap.find('.forward'),
    backward: $wrap.find('.backward'),
    prev: $wrap.find('.prev'),
    next: $wrap.find('.next'),
    prevPage: $wrap.find('.prevPage'),
    nextPage: $wrap.find('.nextPage'),
  };

  // Call Sly on frame
  const slyObject = $frame.sly(Object.assign(baseOptions, options));

  // To Start button
  $wrap.find('.toStart').on('click', function () {
    const item = $(this).data('item');
    // Animate a particular item to the start of the frame.
    // If no item is provided, the whole content will be animated.
    $frame.sly('toStart', item);
  });

  // To Center button
  $wrap.find('.toCenter').on('click', function () {
    const item = $(this).data('item');
    // Animate a particular item to the center of the frame.
    // If no item is provided, the whole content will be animated.
    $frame.sly('toCenter', item);
  });

  // To End button
  $wrap.find('.toEnd').on('click', function () {
    const item = $(this).data('item');
    // Animate a particular item to the end of the frame.
    // If no item is provided, the whole content will be animated.
    $frame.sly('toEnd', item);
  });

  // Add item
  $wrap.find('.add').on('click', function () {
    $frame.sly('add', `<li>${$slidee.children().length}</li>`);
  });

  // Remove item
  $wrap.find('.remove').on('click', function () {
    $frame.sly('remove', -1);
  });

  return slyObject;
}
