function autoScrollNavbar(activeBubble, navbar) {
  const bubbleLeftCorner = activeBubble.offsetLeft;
  const bubbleRightCorner = activeBubble.offsetLeft + activeBubble.offsetWidth;
  const visibleNavLeftCorner = navbar.offsetLeft + navbar.scrollLeft;
  const visibleNavRightCorner = navbar.offsetLeft + navbar.scrollLeft + navbar.offsetWidth;
  const EXTRA_PADDING = 15;

  if (bubbleRightCorner > visibleNavRightCorner) {
    navbar.scrollLeft += activeBubble.offsetWidth + EXTRA_PADDING;
  }
  if (bubbleLeftCorner < visibleNavLeftCorner) {
    navbar.scrollLeft -= activeBubble.offsetWidth + EXTRA_PADDING;
  }
}

function highlightActiveBubble(currHeight, navElements, scrollableNavbar) {
  navElements.forEach((elem) => elem.bubble.removeClass('active'));

  for (let i = 0; i < navElements.length; i++) {
    const { bubble, section } = navElements[i];

    if (section.length === 0) {
      continue;
    }

    if (currHeight > section.position().top && currHeight < section.position().top + section.outerHeight()) {
      bubble.addClass('active');
      autoScrollNavbar(bubble[0], scrollableNavbar);
      return;
    }
  }
}

function setupNavbar() {
  const anchors = $('#navbar a');
  const scrollableNavbar = document.querySelector('#navbar ul');

  const navElements = anchors
    .map((index, bubble) => {
      const sectionID = $(bubble).attr('href').slice(1);
      const section = $(`#${sectionID}`);
      return {
        bubble: $(bubble),
        section: $(section),
      };
    })
    .get();

  window.addEventListener('scroll', () => {
    const HEADER_HEIGHT = 54;
    const currHeight = $(window).scrollTop() + HEADER_HEIGHT;
    highlightActiveBubble(currHeight, navElements, scrollableNavbar);
  });

  scrollableNavbar.addEventListener('wheel', (event) => {
    // Scroll horizontally when hovering the navbar
    if (event.deltaY !== 0) {
      event.preventDefault();
      scrollableNavbar.scrollLeft += event.deltaY;
    }
  });
}

function adjustScrollToTopButtonPlacement() {
  const toTopButton = document.querySelector('#to-top-button');
  if (toTopButton) {
    const containerWidth = document.querySelector('#container').offsetWidth;
    const leftColumnWidth = (window.innerWidth - containerWidth) / 2;
    const centerPosition = leftColumnWidth / 2 - toTopButton.offsetWidth / 2;
    toTopButton.style.left = `${centerPosition}px`;
  }
}

['resize', 'DOMContentLoaded'].forEach((evt) => window.addEventListener(evt, adjustScrollToTopButtonPlacement));

window.addEventListener('scroll', () => {
  const toTopButton = document.querySelector('#to-top-button');
  if (toTopButton) {
    if ($(window).scrollTop() > 700 && window.innerWidth > 1300) {
      toTopButton.style.display = 'flex';
    } else {
      toTopButton.style.display = 'none';
    }
  }
});

document.addEventListener('DOMContentLoaded', () => {
  const navbar = document.querySelector('#navbar');
  if (navbar) {
    setupNavbar();
    // hide header if it is replaced by the navbar
    $('#django-header').addClass('profile-non-sticky-header');
  }
});
