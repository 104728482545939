import FetchService from 'shared-ui/src/utils/fetchService';

const ENDPOINT = '/auth/user/';

export default async function getUserData() {
  try {
    const responseUser = await FetchService.get(ENDPOINT);
    window.userData = responseUser.user || null;

    return window.userData;
  } catch (err) {
    console.error('Error fetching user data', err);

    return err;
  }
}
