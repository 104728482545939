/** *
 Set of utils for tracking various events using dataLayer and GTM
 ** */

import DataLayerService from 'datalayer-service/src/datalayer';
import { AccountAuthenticationAction, AccountAuthenticationUserType } from 'datalayer-service/src/types/enums';

const dataLayerService = new DataLayerService(window);

export function trackLogin(type, accountType, hasTakenQuiz) {
  dataLayerService.accountAuthenticationEvent({
    action: AccountAuthenticationAction.LOGIN,
    authLevel: accountType,
    memberId: window.MEMBER_ID || '',
    userType: hasTakenQuiz ? AccountAuthenticationUserType.MEMBER : AccountAuthenticationUserType.ACCOUNT_HOLDER,
  });
}

export function trackObjectSave(model, slug) {
  let newModel = model;

  if (model === 'mattress') {
    newModel = 'Model';
  } else if (model === 'store' || model === 'location') {
    newModel = 'Retail Location';
  } else {
    newModel = model.charAt(0).toUpperCase() + model.substr(1).toLowerCase();
  }

  dataLayerService.CTAClickEvent({
    category: 'favorite',
    subcategory: 'save',
    level: 'primary',
    pageRegion: newModel,
    product: {
      model: slug,
    },
  });
}

window.trackEnhancedProfileAds = () => {
  const flashAds = $('.ad_container object');
  if (flashAds.length > 0) {
    flashAds.on('mousedown', (e) => {
      let adLoc = '';
      try {
        // eslint-disable-next-line
        adLoc = $(e.currentTarget).parents('[id^="ad_"]').attr('id').split('_')[1];
      } catch (err) {
        // eslint-disable-next-line
        adLoc = '';
      }
      dataLayerService.CTAClickEvent({
        category: 'ad',
        subcategory: 'click',
      });
    });
  }
};
